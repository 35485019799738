<template>
  <div>
    <vx-page-title-actions>
      <vx-button
        :can="canCreate"
        variant="primary"
        :to="createLuckyRoll"
      >
        Add
      </vx-button>
    </vx-page-title-actions>
    <vx-table
      ref="tableRef"
      :resource="resource"
      :columns="columns"
      :filters="filters"
    />
  </div>
</template>

<script>
import {
  VxTable,
  VxCellMoney,
  VxCellLuckyRollPrizes,
  VxCellButtons,
  VxCellBadges
} from '@/components/table'
import {
  activityTypesEnum,
  luckyRolls,
  passDataToResource,
  useResource
} from '@/services/resources'
import { filterTypes, sortOrders } from '@/services/table'
import VxPageTitleActions from '@/components/layout/VxPageTitleActions'
import { VxButton } from '@/components/buttons'
import { buttons } from '@/components/table/vx-table-cells/vx-cell-button'
import { buildRoute, path } from '@/router'
import { boolStatusesOptions, formatToCents, formatToDecimal } from '@/services/utils'

export default {
  name: 'LuckyRolls',

  components: {
    VxTable,
    VxPageTitleActions,
    VxButton,
    VxCellLuckyRollPrizes,
    VxCellBadges
  },
  setup () {
    const resource = passDataToResource(luckyRolls.getAll, {
      requestParams: {
        params: { include: 'prizes' }
      }
    })

    const { can: canCreate } = useResource(luckyRolls.create)
    const { can: canUpdate } = useResource(luckyRolls.update)
    const { can: canDelete } = useResource(luckyRolls.delete)

    const createLuckyRoll = path.luckyRollCreate

    const isActiveButton = ({ id, is_enabled, prizes }) => {
      const payload = {
        requestParams: {
          urlParams: { id },
          data: { is_enabled: !is_enabled }
        }
      }
      const updatedStatusResource = passDataToResource(luckyRolls.update, payload)

      const turnOff = { ...buttons.turnOff, label: 'Enabled' }
      const turnOn = { ...buttons.turnOn, label: 'Disabled' }

      return [{
        ...is_enabled ? turnOff : turnOn,
        disabled: !prizes,
        resource: updatedStatusResource
      }]
    }

    const actionButtons = ({ id }) => {
      const payload = {
        requestParams: { urlParams: { id } }
      }

      const editButton = {
        ...buttons.edit,
        can: canUpdate,
        to: buildRoute(path.luckyRollEdit, { params: { id } })
      }
      const deleteButton = {
        ...buttons.delete,
        can: canDelete,
        resource: passDataToResource(luckyRolls.delete, payload)
      }

      return [editButton, deleteButton]
    }

    const columns = [
      {
        key: 'id',
        sortable: true,
        defaultSort: sortOrders.desc
      },
      {
        key: 'roll_values',
        component: VxCellBadges,
        class: 'max-width-250',
        tdAttr: {
          defaultVariant: 'secondary'
        }
      },
      {
        key: 'min_bet_price',
        sortable: true,
        component: VxCellMoney
      },
      {
        key: 'max_bet_price',
        sortable: true,
        component: VxCellMoney
      },
      { key: 'title' },
      { key: 'activity_type' },
      {
        key: 'prizes',
        component: VxCellLuckyRollPrizes
      },
      {
        key: 'is_enabled',
        label: 'Status',
        sortable: true,
        component: VxCellButtons,
        tdAttr: (value, key, item) => ({
          buttons: isActiveButton(item)
        })
      },
      {
        key: 'actions',
        component: VxCellButtons,
        tdAttr: (value, key, item) => ({
          buttons: actionButtons(item),
          vertical: true
        })
      }
    ]

    const filters = [
      {
        key: 'id',
        type: filterTypes.number
      },
      {
        key: 'bet_price',
        type: filterTypes.number,
        formatterSetter: formatToCents,
        formatterGetter: formatToDecimal
      },
      {
        key: 'roll_value',
        label: 'Rolls',
        type: filterTypes.number
      },
      {
        key: 'is_enabled',
        label: 'Active',
        type: filterTypes.select,
        options: boolStatusesOptions,
        reduce: ({ value }) => value,
        optionsLabel: 'label'
      },
      {
        key: 'activity_type',
        type: filterTypes.select,
        options: Object.values(activityTypesEnum)
      }
    ]

    return {
      columns,
      filters,
      resource,
      canCreate,
      createLuckyRoll
    }
  }
}
</script>
